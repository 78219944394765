import type { FC, ReactNode } from 'react';
import React from 'react';

import { Icon, Space } from '@sravni/react-design-system';
import { ArrowLeft } from '@sravni/react-icons';

import styles from './WithHistory.module.scss';

type Props = {
  children: ReactNode;
  headerContent?: ReactNode;

  onBack: () => void;
};

export const WithHistory: FC<Props> = ({ children, headerContent, onBack }) => (
  <Space direction="vertical" className={styles.container}>
    <Space className={styles.header}>
      <Icon onClick={onBack}>
        <ArrowLeft />
      </Icon>

      {headerContent}
    </Space>
    {children}
  </Space>
);
