import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { Spinner } from '@sravni/react-design-system';
import { Space } from '@sravni/react-design-system/lib/Space';
import { useBoolean } from '@sravni/react-utils';

import styles from './IFrameView.module.scss';

interface IFormViewProps<Payload> {
  url: string;
  useInitLoader: boolean;

  onEventComes?: (event: MessageEvent<Payload>) => void;
  onLoad?: () => void;
}

export function IFrameView<P>({ url, useInitLoader, onEventComes, onLoad }: IFormViewProps<P>) {
  const [isLoading, setIsLoading] = useBoolean(useInitLoader);
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const viewport = window.visualViewport;

    if (!ref.current || !viewport) {
      return;
    }

    const resize = () => {
      if (ref.current) {
        const parentTopOffset = ref.current.parentElement?.offsetTop || 0;
        const visualViewportHeight = (viewport.height || window.innerHeight) - parentTopOffset;
        ref.current.style.height = `${visualViewportHeight}px`;
      }
    };

    const scroll = () => {
      if (ref.current) {
        const parentTopOffset = ref.current.parentElement?.offsetTop || 0;
        const visualViewportHeight = viewport.height || window.innerHeight;
        const visualViewportTopOffset = viewport.offsetTop || 0;
        const innerHeight = visualViewportHeight + visualViewportTopOffset - parentTopOffset;

        ref.current.style.height = `${innerHeight}px`;
      }
    };

    resize();

    viewport.addEventListener('resize', resize);
    viewport.addEventListener('scroll', scroll);

    return () => {
      viewport.removeEventListener('resize', resize);
      viewport.removeEventListener('scroll', scroll);
    };
  }, []);

  useEffect(() => {
    if (!onEventComes) {
      return;
    }

    window.addEventListener('message', onEventComes);

    return () => {
      window.removeEventListener('message', onEventComes);
    };
  }, [onEventComes]);

  return (
    <Space className={styles.container} align="start" justify="start" direction="vertical">
      {isLoading && <Spinner size={28} className={styles.spinner} />}

      <iframe
        onLoad={() => {
          setIsLoading.off();
          onLoad?.();
        }}
        ref={ref}
        className={cn(styles.frame, {
          [styles.invisible]: isLoading,
        })}
        src={url}
        width="100%"
        height="100%"
        title="form"
      />
    </Space>
  );
}
